.wrapper {
  padding-top: 24px;
  padding-bottom: 32px;
  border-top: 1px solid hsla(245, 10%, 22%, 0.15);
}

a {
  text-decoration: none;
  color: #33323d;
}

a:visited {
  color: #33323d;
}

a:hover {
  color: #3e75b4;
}

.title {
  text-align: start;
  margin-bottom: 18px;
}

.text {
  text-align: start;
  margin-bottom: 18px;
  font-size: 20px;
}

.iconContainer {
  display: flex;
}

.iconLink {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.iconLink:not(:last-child) {
  margin-right: 15px;
}

.icon {
  cursor: pointer;
}

.icon:hover {
  filter: brightness(0) saturate(100%) invert(69%) sepia(41%) saturate(415%) hue-rotate(117deg)
    brightness(88%) contrast(80%);
}

/* Tablet Size  */
@media (min-width: 768px) {
  .section {
    margin-top: 94px;
  }

  .wrapper {
    padding-top: 32px;
    padding-bottom: 32px;
    border-top: 1px solid hsla(245, 10%, 22%, 0.15);
  }

  .break {
    display: none;
  }
}

/* Desktop Size  */
@media (min-width: 1440px) {
  .section {
    margin-top: 94px;
  }

  .wrapper {
    padding-top: 48px;
    padding-bottom: 48px;
    border-top: 1px solid hsla(245, 10%, 22%, 0.15);
    display: flex;
    justify-content: space-between;
  }

  .title {
    text-align: start;
    margin-bottom: 24px;
    width: 31.5315%;
  }

  .bodyContainer {
    width: 57.2072%;
  }

  .break {
    display: none;
  }
}
