/* Custom Properties */
:root {
	--primary-color-1: #3e75b4;
	--primary-color-2: #203a4c;
	--primary-color-3: #33323d;

	--secondary-color-1: #fafafa;
	--secondary-color-2: #eaeaeb;
	--secondary-color-3: #f43030;

	--text-color-normal: var(--primary-color-3);
	--text-color-hover: var(--primary-color-1);

	--p-button-color-normal: var(--primary-color-2);
	--p-button-color-hover: var(--primary-color-1);
	--p-button-color-disable: #d2d8db;

	--color-error: var(--secondary-color-3);

	--main-bg-color: var(--secondary-color-1);
	--secondary-bg-color: var(--primary-color-3);

	--mobile-hor-padding: 8.533333%;
	--tablet-hor-padding: 5.078125%;
	--desktop-hor-padding: 11.388889%;
}

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Remove default margin */
* {
	margin: 0;
	padding: 0;
	font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
	list-style: none;
}

/* Set core root defaults */
html:focus-within {
	scroll-behavior: smooth;
}

html,
body {
	height: 100%;
	background-color: var(--main-bg-color);
}

/* Set core body defaults */
body {
	text-rendering: optimizeSpeed;
	line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
	max-width: 100%;
	display: block;
}

/* Utilities */
.h1 {
	font-family: 'Almarai';
	font-style: normal;
	font-weight: 700;
	font-size: 50px;
	line-height: 50px;
	letter-spacing: -0.45px;
	color: #33323d;
	text-align: start;
}

.h2 {
	font-family: 'Almarai';
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 42px;
	letter-spacing: -0.36px;
	color: #33323d;
	text-align: start;
}

.h3,
.project-nav-label {
	font-family: 'Almarai';
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 36px;
	letter-spacing: -0.29px;
	color: #33323d;
}

.body-1 {
	font-family: 'Public Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 30px;
	color: #33323d;
}

.body-2 {
	font-family: 'Public Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 30px;
	color: #33323d;
}

/* Mobile Size  */
.container {
	margin: 0px;
	width: 100%;
	padding-left: var(--mobile-hor-padding);
	padding-right: var(--mobile-hor-padding);
	position: relative;
}

/* Tablet Size  */
@media (min-width: 768px) {
	.container {
		padding-left: var(--tablet-hor-padding);
		padding-right: var(--tablet-hor-padding);
	}
}

/* Desktop Size  */
@media (min-width: 1440px) {
	.container {
		padding-left: var(--desktop-hor-padding);
		padding-right: var(--desktop-hor-padding);
	}
}

@media (max-width: 700px) {
	.project-nav-box {
		margin-left: 15px;
		margin-right: 15px;
	}
}

@media (max-width: 600px) {
	.project-nav-label {
		font-size: 24px;
		font-weight: 500;
	}
}
