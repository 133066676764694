.main {
  margin-top: 0px;
  white-space: pre-line;
}


/* Tablet Size  */
@media (min-width: 768px) {
  .main {
    margin-top: 94px;
  }
}

/* Desktop Size  */
@media (min-width: 1440px) {
  .main {
    margin-top: 94px;
  }
}
