.section1 {
	margin-bottom: 70px;
}

.picture {
	margin-bottom: 24px;
}

.image {
	width: 100%;
	height: 271px;
}

.text {
	margin-top: 50px;
  margin-bottom: 32px;
}



.fadeImage {
	opacity: 0.7;
}

.darkOverlay {
	position: relative;
}

.darkOverlay::after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(
		0,
		0,
		0,
		0.5
	); /* Here 0.5 is the opacity of black color. Adjust as needed */
}

.buttonDiv {
	margin-left: none;
}

/* Tablet Size  */
@media (min-width: 768px) {
	.section1 {
		margin-bottom: 70px;
	}

	.picture {
		margin-bottom: 0px;
	}

	.image {
		width: 100%;
		height: 600px;
	}

	.boxContainer {
		background-color: transparent;
		position: absolute;
		bottom: -1px;
		left: calc(var(--tablet-hor-padding) - 1px);
		width: 66.92708%;
		min-height: 278px;
		height: fit-content;
		margin-left: 15px;
		margin-bottom: 15px;
		padding: 56px 56px 0px 0px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.text {
		margin-bottom: 48px;
		color: white;
		margin-left: 20px;
	}

	.buttonDiv {
		margin-left: 15px;
	}
}

/* Desktop Size  */
@media (min-width: 1440px) {
	.section1 {
		margin-bottom: 70px;
	}

	.picture {
		margin-bottom: 0px;
	}

	.image {
		width: 100%;
	}

	.boxContainer {
		/* background-color: transparent;
    position: absolute;
    
    left: calc(var(--desktop-hor-padding) - 1px);
    width: 30.90277%;
    min-height: 357px;
    height: fit-content;
    padding: 56px 55px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; */
		position: absolute;
		left: calc(var(--desktop-hor-padding) - 1px);
	}

	.text {
		margin-bottom: 53px;
		color: white;
		margin-left: 20px;
	}

	.buttonDiv {
		margin-left: 15px;
	}
}
