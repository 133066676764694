/* Mobile Size  */
.header {
	color: white;
	height: 50px;
	background-color: black;
	text-align: center;
	padding-top: 15px;
}

.item {
	font-family: 'Public Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	margin-top: 15px;
	margin-bottom: 15px;
	text-align: center;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #ffffff;
	text-decoration: none;
}

.item:hover {
	color: var(--text-color-hover);
}

.item:visited {
	color: white;
}

.menu {
	cursor: pointer;
	background-color: transparent;
	border: none;
}

/* Table Size  */
@media (min-width: 768px) {
	.header {
		margin-bottom: 47px;
	}

	.item {
		margin-bottom: 0px;
	}

	.item:not(:last-child) {
		margin-right: 42px;
	}

	.item:hover {
		color: var(--text-color-hover);
	}
}

/* Desktop Size  */
@media (min-width: 1440px) {
	.header {
		margin-bottom: 54px;
	}

	.item {
		margin-bottom: 0px;
	}

	.item:not(:last-child) {
		margin-right: 42px;
	}

	.item:hover {
		color: var(--text-color-hover);
	}
}
